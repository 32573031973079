<template>
  <div class="WJgroupHonor">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../../assets/image/zihibanner.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div>
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">

          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{$t('honor.title')}}</p>
              <!-- <p class="WJgroup-subtitle">WEIGA Group Introduction</p> -->
            </div>
          </div>

          <div class="WJgroupHonor-time">
            <div class="WJgroupHonor-taxis">
              <div class="WJgroupHonor-leftIcon WJgroupHonor-Icon" @mousemove="leftArrowShow" @click="moveRight">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="WJgroupHonor-rightIcon WJgroupHonor-Icon" @mousemove="rightArrowShow" @click="moveLeft">

                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="WJgroupHonor-taxisbox" ref="atlasulBox">
                <ul class="WJgroupHonor-taxisul" :style="{'width':ulwidth+'px','left':leftMove+'px'}">
                  <li  v-for="(item,index ) in yearList" @click="choseYears(index)"
                    :style="{'width':liBoxwidth+'px'}">
                    <span class="WJgroupHonor-taxiQuan "
                      :class="yearIndex==index?'WJgroupHonor-taxiActiveQuan':''"></span>
                    <div class="animate__animated  "
                      :class="yearIndex==index?'WJgroupHonor-taxisActiveDiv animate__headShake':''">
                      {{item.typename}}
                    </div>
                  </li>
                </ul>
              </div>

            </div>

            <ul class="WJgroupHonor-tcontentul">
              <li v-for="(item,index) in newsList" class="wow animate__animated bounceInRight "
                :data-wow-delay="(index/10)+'s'">
<!--                <h2>{{item.subsidiary}}</h2>-->

                <h3 class="honor-title">{{item.title}}</h3>
                <h4></h4>
                <h5> {{item.subtitle}}</h5>
              </li>
            </ul>
          </div>

        </el-col>

      </el-row>
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/WJgroupHonor',
        menu: {
          name: '维佳集团',
          path: '/WJgroupIntroduce',
          list: [{
            name: this.$t('menu.Introduction'),
            path: '/WJgroupIntroduce',
          }, {
            name: this.$t('menu.Message'),
            path: '/WJgroupSendWord',
          },{
            name: this.$t('menu.develop'),
            path: '/WJgroupDevelop',
          }, {
            name:this.$t('menu.honour'),
            path: '/WJgroupHonor',
          }, {
            name:this.$t('menu.team'),
            path: '/WJgroupTeam',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.group'),
          path: '/WJgroupIntroduce',
        }, {
          name: this.$t('menu.honour'),
          path: '/WJgroupHonor',
        }],
        yearList: [],
        yearIndex: 0,
        lArrowShow: true,
        rArrowShow: true,
        liBoxwidth: 180,
        ulwidth: null,
        leftMove: 0,
        newsList:[]
      };
    },
    computed: {

    },
    created() {
      if(document.documentElement.clientWidth<750){
        this.liBoxwidth=100
      }
      this.getYearList()
    },
    mounted() {
      new this.$wow.WOW().init()

    },
    methods: {
      resetWidth(){
        this.$nextTick(() => {
          let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
          this.ulwidth = (this.liBoxwidth * this.yearList.length) + (this.liBoxwidth * 1)
          if (this.ulwidth < ulBoxwidth) {
            this.ulwidth = ulBoxwidth
          }
        })
      },
      getYearList(){
        this.$init.getChannelList(channelList=>{
          let yearList = channelList.filter(item=>{
            return item.parent_id==74
          });
          this.yearList =yearList;
          this.resetWidth();
          this.getData();
        })
      },
      getData(){

        let type =this.yearList[this.yearIndex];
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.newsList=[];
        this.$request.post(api,{
          apiArclist_1:`ekey=1&typeid=${type.id}&limit=100&addfields=subsidiary,subsidiary_en,subtitle_en,title_en`,//获取栏目文章列表
        }).then(res=>{
          this.newsList =this.$utils.translate(res.apiArclist[1].data);
        })
      },
      moveLeft() {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        let moveWith = ulBoxwidth - this.ulwidth;

        if (this.leftMove > moveWith) {
          let Move = Math.abs(moveWith - this.leftMove)
          if (Move < this.liBoxwidth) {
            this.leftMove = this.leftMove - Move
          } else {
            this.leftMove = this.leftMove - this.liBoxwidth
          }


        }
      },
      moveRight() {
        let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
        let moveWith = ulBoxwidth - this.ulwidth;
        let moveOne = this.ulwidth / (this.yearList.length - 1)
        if (this.leftMove < 0) {
          let Move = Math.abs(moveWith - this.leftMove)
          if (Move > this.liBoxwidth) {
            this.leftMove = 0
          } else {
            this.leftMove = this.leftMove + this.liBoxwidth
          }
        }
      },
      leftArrowShow() {
        this.lArrowShow = false
        this.rArrowShow = true
      },
      rightArrowShow() {
        this.rArrowShow = false
        this.lArrowShow = true
      },
      choseYears(index) {
        if(this.yearIndex ==index){
          return
        }
        this.yearIndex = index
        this.getData();
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .gIntroduce-banner {
    width: 100%;
    height: auto;
  }

  .gIntroduce-banner img {
    width: 100%;
    height: auto;
  }



  .WJgroupIntroduce-title {
    text-align: center;
    position: relative;
    padding-bottom: 140px !important;
  }

  .WJgroupIntroduce-titleIMg {
    width: 100%;
    height: 148px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .WJgroupIntroduce-titleIMg img {
    height: 100%;
  }

  .WJgroupIntroduce-title h1 {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupIntroduce-title h2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 68px;
  }

  .WJgroupHonor-time {
    margin-bottom: 50px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxis {
    width: 87%;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    position: relative;
  }

  .WJgroupHonor-taxisbox {
    width: auto;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
  }

  .WJgroupHonor-taxisbox::-webkit-scrollbar {
    display: none;
  }

  .WJgroupHonor-Icon {
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #b08e68;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    transition: 1s;
  }

  .WJgroupHonor-Icon:hover {
    background-color: #b08e68;

  }

  .WJgroupHonor-Icon:hover i {
    color: #ffffff !important;

  }

  .WJgroupHonor-Icon i {
    color: #b08e68;
    transition: 1s;
    font-size: 30px
  }

  .WJgroupHonor-leftIcon {
    left: 0;
  }

  .WJgroupHonor-rightIcon {
    right: 0;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul {
    // width: 1600px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    transition: 0.5s;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #B08E68;
    position: absolute;
    bottom: 20px;
    left: 0px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li:first-child {
    margin-left: 90px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li:last-child {
    margin-right: 90px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li {
    width: 180px;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li div {
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -40px;
    font-size: 42px;
    font-family: Impact;
    font-weight: 400;
    color: #CCCCCC;
    line-height: 45px;
  }

  .WJgroupHonor-time .WJgroupHonor-taxisul li .WJgroupHonor-taxisActiveDiv {
    color: #B08E68;
  }

  .WJgroupHonor-taxiQuan {
    position: absolute;
    bottom: 14px;
    left: 50%;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border: 1px solid #B08E68;
    border-radius: 50%;

  }

  .WJgroupHonor-taxiActiveQuan {
    background: #B08E68;
    border-radius: 50%;
  }

  .WJgroupHonor-tcontentul {
    padding: 80px 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .WJgroupHonor-tcontentul li {
    padding: 22px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: normal;
    line-height: 28px;
  }

  .WJgroupHonor-tcontentul li:nth-child(5n-4) h2 {
    background: #919CB3;
  }

  .WJgroupHonor-tcontentul li:nth-child(5n-3) h2 {
    background: #B3B885;
  }

  .WJgroupHonor-tcontentul li:nth-child(5n-2) h2 {
    background: #BB99B7;
  }

  .WJgroupHonor-tcontentul li:nth-child(5n-2) h2 {
    background: #A9AAAC;
  }

  .WJgroupHonor-tcontentul li:nth-child(5n) h2 {
    background: #B4A59A;
  }

  .WJgroupHonor-tcontentul li h2 {
    width: 80px;
    flex-shrink: 0;
    height: 24px;
    line-height: 24px;
    background: #919CB3;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 20px;
  }

  .WJgroupHonor-tcontentul li h3 {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D3A;
  }
  .honor-title{
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .honor-title:hover{
    white-space: normal!important;
    overflow:inherit !important;
    text-overflow: normal!important;
  }
  .WJgroupHonor-tcontentul li h4 {
    flex: 1;
    position: relative;
    margin: 0 10px;
  }

  .WJgroupHonor-tcontentul li h4:before {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #aaa;
  }

  .WJgroupHonor-tcontentul li h5 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupHonor .WJgroupHonor-time .WJgroupHonor-taxisul li div{
      font-size: 48px;
      line-height: 58px;
    }
    .WJgroupHonor .WJgroupHonor-taxis{
    width: 100%;
    }
    .WJgroupHonor .WJgroupHonor-tcontentul{
      width: 100%;
    }
    .WJgroupHonor .WJgroupHonor-tcontentul li{
      display: block;
    }
    .WJgroupHonor .WJgroupHonor-tcontentul li h4{
      display: none;
    }
    .WJgroupHonor-tcontentul li .honor-title{
      max-width: 100%;
      font-size: 39px;
      line-height: 58px;
      white-space: normal;
      overflow: auto;
      text-overflow: unset;
    }
    .WJgroupHonor-tcontentul li h5{
      font-size: 34px;
      line-height: 44px;
      margin-top: 10px;
    }
  }

</style>
